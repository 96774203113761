<template>
  <div>
    <content-section :class="getCanvasSizeClass">
      <div class="grid place-items-center">
        <img
          src="@/assets/placeholder/under-development-v1-1x.jpg"
          alt="Under Development Illustration"
          srcset=""
        />
      </div>
      <div
        class="flex flex-col items-center justify-center max-w-sm pb-10 mx-auto text-center canvas-mt"
      >
        <oto-typography variant="one" :text="title" class="block" />

        <oto-typography
          variant="three"
          :text="subtitleOne"
          class="block mt-6"
        />
        <oto-typography variant="three" :text="subtitleTwo" class="block" />
      </div>
    </content-section>
  </div>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import OtoTypography from '@/components/ui/OtoTypography'

export default {
  name: 'UnderDevelopment',
  props: {
    title: { required: false, default: 'Under Development' },
    subtitleOne: {
      required: false,
      default: 'Hold Tight! The page / feature is being actively developed.',
    },
    subtitleTwo: {
      required: false,
      default: "It'll soon be alive.",
    },
    canvasSize: { required: false, default: 'auto' },
  },
  components: {
    ContentSection,
    OtoTypography,
  },
  computed: {
    getCanvasSizeClass() {
      let sizes = {
        auto: 'canvas-auto h-auto w-auto',
        screen: 'canvas-screen h-screen grid place-items-center',
      }
      return this.canvasSize in sizes ? sizes[this.canvasSize] : sizes['auto']
    },
  },
}
</script>

<style lang="scss" scoped>
.canvas-auto {
  .canvas-mt {
    margin-top: 2rem !important;
  }
}
.canvas-screen {
  .canvas-mt {
    margin-top: -5rem !important;
  }
}
</style>
