<template>
  <span :class="getTypography()">
    <slot>{{ getText }}</slot>
  </span>
</template>

<script>
export default {
  name: 'OtoTypography',
  props: {
    text: { required: false, type: String },
    fallback: { required: false, type: String },
    variant: { required: false, type: String, default: 'three' },
  },
  data() {
    return {
      // title
      typographyOne: 'font-semibold text-oDark text-19px',
      // subtitle
      typographyTwo: 'font-semibold text-14px text-oDark',
      // subtitle->value
      typographyThree: 'font-medium text-14px text-oLightGray',
      // fallback
      fallbackTypography: 'font-medium text-14px text-oGray',
    }
  },
  computed: {
    getText() {
      return this.text ?? this.fallback
    },
  },
  methods: {
    getTypography() {
      if (this.variant === 'one' || this.variant === '19pxTitle') {
        return this.typographyOne
      }
      if (this.variant === 'two' || this.variant === '14pxSubtitle') {
        return this.typographyTwo
      }
      if (this.variant === 'three' || this.variant === '14pxValue') {
        return this.typographyThree
      }
      return this.fallbackTypography
    },
  },
}
</script>

<style lang="scss" scoped></style>
