<template>
  <div>
    <under-development />
  </div>
</template>

<script>
import UnderDevelopment from '@/components/placeholder/UnderDevelopment'

export default {
  name: 'ViewFleetPromotions',
  components: { UnderDevelopment },

  data() {
    return {
      fleet: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {},
}
</script>

<style lang="scss"></style>
